<template>
    <div class="">
        <div class="flex justify-between items-center mb-6">
            <div>
                <h1 class="text-4xl">Dissolve Commission</h1>
                <!-- <p class="text-sm text-gray-400">Welcome to your dashboard</p> -->
            </div>
        </div>

        <div class="grid grid-cols-2 gap-4">
            <div class="flex flex-col">
                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div
                        class="
                            py-2
                            align-middle
                            inline-block
                            min-w-full
                            sm:px-6
                            lg:px-8
                        "
                    >
                        <div
                            class="
                                shadow
                                overflow-hidden
                                border-b border-gray-200
                                sm:rounded-lg
                            "
                        >
                            <table class="min-w-full divide-y divide-gray-200">
                                <thead class="bg-gray-50">
                                    <tr>
                                        <th
                                            scope="col"
                                            class="
                                                px-6
                                                py-3
                                                text-left text-xs
                                                font-medium
                                                text-gray-500
                                                uppercase
                                                tracking-wider
                                            "
                                        >
                                            Acccount
                                        </th>

                                        <th
                                            scope="col"
                                            class="
                                                px-6
                                                py-3
                                                text-left text-xs
                                                font-medium
                                                text-gray-500
                                                uppercase
                                                tracking-wider
                                            "
                                        >
                                            Amount
                                        </th>
                                    </tr>
                                </thead>
                                <div
                                    v-if="isLoading"
                                    class="text-center flex justify-center"
                                >
                                    <span> Loading..... </span>
                                </div>
                                <tbody
                                    v-else
                                    class="bg-white divide-y divide-gray-200"
                                >
                                    <tr
                                        v-for="(
                                            commissions_debit, i
                                        ) in commissions_debits"
                                        :key="i"
                                    >
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div class="flex items-center">
                                                <b>{{
                                                    commissions_debit
                                                        .commission_account.name
                                                }}</b>
                                            </div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div
                                                class="
                                                    text-sm
                                                    font-medium
                                                    text-gray-900
                                                "
                                            >
                                                GMD
                                                {{
                                                    commissions_debit.debit /
                                                    100
                                                }}
                                            </div>
                                        </td>
                                    </tr>
                                    <!-- More people... -->
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="my-6 mx-11">
                    <form class="w-full" @submit.prevent="showConfirmModal()">
                        <div class="group">
                            <label
                                class="
                                    block
                                    text-gray-700 text-sm
                                    font-normal
                                    mb-2
                                "
                                for="username"
                            >
                                Select account
                            </label>
                            <select
                                v-model="form.account_id"
                                class="
                                    shadow
                                    appearance-none
                                    border
                                    rounded
                                    w-full
                                    py-2
                                    px-3
                                    text-gray-700
                                    leading-tight
                                    focus:outline-none focus:shadow-outline
                                "
                            >
                                <option selected disabled value="">
                                    Select a commission from here
                                </option>
                                <option
                                    v-for="(account, i) in accounts"
                                    :key="i"
                                    :value="account.id"
                                >
                                    {{ account.name }} (Current Balance:
                                    {{ formatPrice(account.balance / 100) }})
                                </option>
                            </select>
                        </div>

                        <div class="group my-4">
                            <label
                                class="
                                    block
                                    text-gray-700 text-sm
                                    font-normal
                                    mb-2
                                "
                                for="amount"
                            >
                                Amount
                            </label>
                            <input
                                class="
                                    shadow
                                    appearance-none
                                    border
                                    rounded
                                    w-full
                                    py-2
                                    px-3
                                    text-gray-700
                                    leading-tight
                                    focus:outline-none focus:shadow-outline
                                "
                                name="name"
                                v-model="form.amount"
                                type="number"
                                :class="{
                                    'is-invalid': form.errors.has('amount'),
                                }"
                                required
                                placeholder="Enter amount"
                            />
                        </div>

                        <div class="group">
                            <button
                                :class="{
                                    'opacity-50 cursor-not-allowed': isLoading,
                                }"
                                class="
                                    px-4
                                    py-2
                                    rounded
                                    text-white
                                    inline-block
                                    shadow-lg
                                    bg-blue-500
                                    hover:bg-blue-600
                                    focus:bg-blue-700
                                    w-full
                                    disabled:opacity-50
                                "
                                @click="showConfirmModal()"
                            >
                                <span v-if="isLoading">Loading...</span>
                                <span v-else>Submit</span>
                            </button>
                        </div>
                    </form>

                    <div>
                        <confimation
                            v-show="isConfirmModalVisible"
                            modalHeadline="Please confirm?"
                            :isLoading="isLoading"
                            :confirmMessage="`Are you sure you want to dissolve GMD${form.amount} `"
                            @confirmEvent="confirmRequest"
                            @close="closeConfirmModal"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Form from 'vform';
import axios from 'axios';
// import BranchSales from "../../components/Charts/BranchSales";
import Confimation from '../../components/Confimation.vue';

export default {
    middleware: 'auth',
    layout: 'default',
    components: {
        Confimation,
    },

    data: () => ({
        bufferTransactions: null,
        accounts: [],
        isLoading: false,
        isConfirmModalVisible: false,
        commissions_debits: [],

        form: new Form({
            account_id: '',
            amount: '',
        }),
    }),

    created() {
        this.getCommissionDebits();
        this.loadAccounts();
    },

    methods: {
        total(param) {
            return param.reduce(function (total, item) {
                return total + item.balance_allocated;
            }, 0);
        },

        showConfirmModal() {
            this.isConfirmModalVisible = true;
        },

        closeConfirmModal() {
            this.isConfirmModalVisible = false;
        },

        confirmRequest(req) {
            console.log('User has confirmed', req);
            this.userHasConfirmed = req;

            if (this.userHasConfirmed == 'yes') {
                this.onSubmit();
            }
        },

        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace(',', '.');
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },

        async onSubmit() {
            // Submit the form
            this.isLoading = true;
            console.log('form data', this.form);

            try {
                const result = await this.form.post('/commission/dissolve');

                if (result.status == 200) {
                    this.getCommissionDebits();
                    this.loadAccounts();
                }

                this.form.account_id = '';
                this.form.amount = '';

                this.closeConfirmModal();
                this.isLoading = false;

                // this.$router.push({ path: "/manage_floats" });

                return true;
            } catch (e) {
                this.isLoading = false;

                console.log('Error');
                return false;
            }
        },

        async loadAccounts() {
            // console.log("LLLLLLLL");
            try {
                this.isLoading = true;

                const { data } = await axios.get(`/commission/accounts`);

                this.accounts = data;
                this.isLoading = false;

                return true;
            } catch (e) {
                this.isLoading = false;

                console.log('Error', e);
                return false;
            }
        },

        async getCommissionDebits() {
            // console.log("LLLLLLLL");
            try {
                this.isLoading = true;
                const { data } = await axios.get(`/commission_dissolves`);

                this.commissions_debits = data.data;
                this.isLoading = false;
            } catch (e) {
                this.isLoading = false;

                console.log('Error', e);
            }
        },
    },
};
</script>
